import { notEmptyString, validEmail } from "../validators";

export class UserAccount {
  _id = "";
  nickName = "";
  fullName = "";
  identifier = "";
  roles: string[] = [];
  createdAt = new Date();
  modifiedAt = new Date();
  userLang = "";
  translate: any[] = [];
  languageList: any[] = [];
  constructor(inData: any = null) {
    if (inData instanceof Object) {
      Object.entries(inData).forEach(([k, v]) => {
        if (typeof v === "string") {
          switch (k) {
            case "_id":
            case "nickName":
            case "fullName":
              this[k] = v;
              break;
            case "userLang":  // Add this case to handle languageId
              this[k] = v;
              break;
            case "identifier":
            case "email":
              this.identifier = v;
              break;
            case "createdAt":
            case "modifiedAt":
              this[k] = new Date(v);
              break;
          }
        } else if (v instanceof Array && k === "roles") {
          this.roles = v;
        }
        else if (v instanceof Array && k === "translate") {
          this.translate = v.map((translationItem) => {
            return {
              _id: translationItem._id,
              category: translationItem.category,
              key: translationItem.key,
              type: translationItem.type,
              base: translationItem.base,
              translate: translationItem.translate.map((langTranslation) => ({
                isPublished: langTranslation.isPublished,
                _id: langTranslation._id,
                languageId: langTranslation.languageId,
                value: langTranslation.value,
              })),
              createdAt: new Date(translationItem.createdAt),
            };
          });
        }
        else if (v instanceof Array && k === "languageList") {
          this.languageList = v.map((languageItem) => {
            return {
              _id: languageItem._id,
              language: languageItem.language,
              key: languageItem.key,
              mobileKey: languageItem.mobileKey,
            }
          });
        };
      });
    }
  }

  get hasIdentifier(): boolean {
    return validEmail(this.identifier);
  }

  get id(): string {
    return this._id;
  }

  setId(id = ""): UserAccount {
    if (notEmptyString(id)) {
      this._id = id;
    }
    return this;
  }
}
