
	import { Vue, Options } from "vue-class-component";
	import { Watch } from "vue-property-decorator";
	import PasswordMeter from "vue-simple-password-meter";
	import { fetchRemoteRoleOptions, fetchUserFromHash, resetPass } from "@/api/methods";
	import { Role } from "@/api/models/role";
	import { UserAccount } from "@/api/models/user";
	import { notEmptyString, validEmail, validPassword } from "@/api/validators";
	import { clearLocal, fromLocal, toLocal } from "@/api/localstore";
	import InputText from "primevue/inputtext";
	import { watch } from "vue";

	@Options({
		components: {
			PasswordMeter,
		},
	})
	export default class Manage extends Vue {
		hash = "";
		roles: any[] = [];
		user: UserAccount = new UserAccount();
		password = "";
		cpassword = "";
		errorMsgs: string[] = [];
		strengthValue = 0;
		minLength = 4;
		hasUpdated = false;
		languageList: any[] = [];
		selectedLanguage: string | null = null;
		abc: string | null = null;
		created() {
			this.fetchRoles();
			this.matchHash();
		}

		get passwordsMatch() {
			return notEmptyString(this.password, this.minLength) && this.password === this.cpassword;
		}

		// get introMsg() {
		// 	if (this.hasHash) {
		// 		if (notEmptyString(this.user.identifier, 5)) {
		// 			return `Please reset the password for ${this.user.identifier}.`;
		// 		} else {
		// 			return `We cannot find a user with this temporary token`;
		// 		}
		// 	} else {
		// 		return `We cannot find a user matching this account. The token may have expired or be otherwise invalid.`;
		// 	}
		// }

		get introMsg() {
			if (this.hasHash) {
				if (notEmptyString(this.user.identifier, 5)) {
					if (this.selectedLanguage) {
						let translationValue = this.getTranslation(this.selectedLanguage, "intro_instruction_message_text");
						if (translationValue) {
							translationValue = translationValue.replace(new RegExp("%this.user.identifier%", "g"), this.user.identifier);
							return translationValue;
						} else {
							return `Please reset the password for ${this.user.identifier}.`;
						}
					} else if (this.user.userLang) {
						let translationValue = this.getTranslation(this.user.userLang, "intro_instruction_message_text");
						if (translationValue) {
							translationValue = translationValue.replace(new RegExp("%this.user.identifier%", "g"), this.user.identifier);
							return translationValue;
						} else {
							return `Please reset the password for ${this.user.identifier}.`;
						}
					} else {
						return `Please reset the password for ${this.user.identifier}.`;
					}
				} else {
					return `We cannot find a user with this temporary token`;
				}
			} else {
				return `We cannot find a user matching this account. The token may have expired or be otherwise invalid.`;
			}
		}

		get title() {
			return this.hasUpdated ? "Successful password reset" : "Reset your password";
		}

		get hasHash(): boolean {
			return notEmptyString(this.hash, 12);
		}

		get hasUser(): boolean {
			return validEmail(this.user.identifier) && notEmptyString(this.user.nickName);
		}

		get showForm(): boolean {
			return this.hasUser && !this.hasUpdated;
		}

		get showUpdatedMessage(): boolean {
			return this.hasUser && this.hasUpdated;
		}

		get showIntro(): boolean {
			return !this.hasUpdated;
		}

		get updatedMessage() {
			if (this.selectedLanguage) {
				let translationValue = this.getTranslation(this.selectedLanguage, "updated_message_text");
				if (translationValue) {
					translationValue = translationValue.replace(new RegExp("%this.user.identifier%", "g"), this.user.identifier);
					return translationValue;
				} else {
					return `The password for ${this.user.identifier} has been successfully updated. You may now sign in with your new password`;
				}
			} else if (this.user.userLang) {
				let translationValue = this.getTranslation(this.user.userLang, "updated_message_text");
				if (translationValue) {
					translationValue = translationValue.replace(new RegExp("%this.user.identifier%", "g"), this.user.identifier);
					return translationValue;
				} else {
					return `The password for ${this.user.identifier} has been successfully updated. You may now sign in with your new password`;
				}
			}
			return `The password for ${this.user.identifier} has been successfully updated. You may now sign in with your new password`;
		}

		get hasError(): boolean {
			return this.errorMsgs.length > 0;
		}

		get passwordClasses() {
			const cls: string[] = [];
			if (this.password.length > 4) {
				if (validPassword(this.password)) {
					// cls.push("good");
				} else {
					// cls.push("not-good");
				}
			}
			return cls;
		}

		get confirmClasses() {
			const cls: string[] = [];
			if (this.passwordsMatch) {
				// cls.push("good");
			} else if (this.cpassword.length == this.password.length && !this.passwordsMatch && this.cpassword.length > 1 && this.password.length > 1) {
				// cls.push("not-good");
			}
			return cls;
		}

		get newPasswordText() {
			if (this.selectedLanguage) {
				const translationValue = this.getTranslation(this.selectedLanguage, "form_new_password_text");
				this.validate();
				return translationValue && !null ? translationValue : "New password";
			} else if (this.user.userLang) {
				const translationValue = this.getTranslation(this.user.userLang, "form_new_password_text");
				return translationValue && !null ? translationValue : "New password";
			} else {
				return "New password";
			}
		}

		get cpasswordText() {
			if (this.selectedLanguage) {
				const translationValue = this.getTranslation(this.selectedLanguage, "form_confirm_password_text");
				this.validate();
				return translationValue && !null ? translationValue : "Confirm your password";
			} else if (this.user.userLang) {
				const translationValue = this.getTranslation(this.user.userLang, "form_confirm_password_text");
				return translationValue && !null ? translationValue : "Confirm your password";
			} else {
				return "Confirm your password";
			}
		}

		get submitLabel() {
			if (this.selectedLanguage) {
				const translationValue = this.getTranslation(this.selectedLanguage, "submit_label_text");
				return translationValue && !null ? translationValue : "Update password";
			} else if (this.user.userLang) {
				const translationValue = this.getTranslation(this.user.userLang, "submit_label_text");
				return translationValue && !null ? translationValue : "Update password";
			} else {
				return "Update password";
			}
		}

		get headingMsgText() {
			if (this.hasUpdated) {
				if (this.selectedLanguage) {
					const translationValue = this.getTranslation(this.selectedLanguage, "updated_heading_message_text");
					return translationValue && !null ? translationValue : "Successful password reset";
				} else if (this.user.userLang) {
					const translationValue = this.getTranslation(this.user.userLang, "updated_heading_message_text");
					return translationValue && !null ? translationValue : "Successful password reset";
				} else {
					return "Successful password reset";
				}
			} else {
				if (this.selectedLanguage) {
					const translationValue = this.getTranslation(this.selectedLanguage, "reset_password_heading_message_text");
					return translationValue && !null ? translationValue : "Reset your password";
				} else if (this.user.userLang) {
					const translationValue = this.getTranslation(this.user.userLang, "reset_password_heading_message_text");
					return translationValue && !null ? translationValue : "Reset your password";
				} else {
					return "Reset your password";
				}
			}
		}

		get newPasswordInputText() {
			if (this.selectedLanguage) {
				const translationValue = this.getTranslation(this.selectedLanguage, "form_input_new_password_text");
				return translationValue && !null ? translationValue : "Enter password";
			} else if (this.user.userLang) {
				const translationValue = this.getTranslation(this.user.userLang, "form_input_new_password_text");
				return translationValue && !null ? translationValue : "Enter password";
			} else {
				return "Enter password";
			}
		}

		get cPasswordInputText() {
			if (this.selectedLanguage) {
				const translationValue = this.getTranslation(this.selectedLanguage, "form_input_confirm_password_text");
				return translationValue && !null ? translationValue : "Confirm password";
			} else if (this.user.userLang) {
				const translationValue = this.getTranslation(this.user.userLang, "form_input_confirm_password_text");
				return translationValue && !null ? translationValue : "Confirm password";
			} else {
				return "Confirm password";
			}
		}
		fetchRoles() {
			fetchRemoteRoleOptions().then((data) => {
				if (data instanceof Array) {
					this.roles = data
						.filter((item) => item instanceof Object)
						.map((item) => new Role(item))
						.filter((item) => !item.adminAccess);
				}
			});
		}

		checkUpdated() {
			const cKey = "updated";
			const mins = 60;
			const stored = fromLocal(cKey, mins * 60);
			if (!stored.expired) {
				if (stored.data instanceof Object) {
					const { hash, user } = stored.data;
					if (notEmptyString(hash) && user instanceof Object) {
						this.hasUpdated = hash === this.hash || this.hash === "updated";
						if (this.hasUpdated) {
							this.user = new UserAccount(user);
						}
					}
				}
			}
		}

		matchHash(): void {
			const { path } = this.$route;
			const parts = path.split("reset/");
			if (parts.length > 1) {
				const last = parts.pop();
				if (typeof last === "string") {
					this.hash = last;
					this.fetchUserInfo();
					setTimeout(this.checkUpdated, 250);
				}
			}
		}

		fetchUserInfo() {
			if (notEmptyString(this.hash, 12)) {
				fetchUserFromHash(this.hash).then((data) => {
					if (data instanceof Object) {
						if (notEmptyString(data.identifier)) {
							this.user = new UserAccount(data);
							clearLocal("updated");
						}
					}
				});
			}
		}

		onScore(result) {
			if (result instanceof Object) {
				this.strengthValue = result.score;
			}
		}

		validate(beforeSubmit = true) {
			this.errorMsgs = [];
			const hasPassword = notEmptyString(this.password, 4);
			const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]*$/;
			if (!regex.test(this.password)) {
				if (this.selectedLanguage) {
					const translationValue = this.getTranslation(this.selectedLanguage, "password_letter_validation_text");
					if (translationValue) {
						this.errorMsgs.push(translationValue);
					} else {
						this.errorMsgs.push("Password should contain at least one upper case character, one lower case character, one special character and one number.");
					}
				} else if (this.user.userLang) {
					const translationValue = this.getTranslation(this.user.userLang, "password_letter_validation_text");
					if (translationValue) {
						this.errorMsgs.push(translationValue);
					} else {
						this.errorMsgs.push("Password should contain at least one upper case character, one lower case character, one special character and one number.");
					}
				} else {
					this.errorMsgs.push("Password should contain at least one upper case character, one lower case character, one special character and one number.");
				}
			}
			if (beforeSubmit && hasPassword && this.password !== this.cpassword) {
				if (this.selectedLanguage) {
					const translationValue = this.getTranslation(this.selectedLanguage, "password_match_validation_text");
					if (translationValue) {
						this.errorMsgs.push(translationValue);
					} else {
						this.errorMsgs.push("Your passwords do not match");
					}
				} else if (this.user.userLang) {
					const translationValue = this.getTranslation(this.user.userLang, "password_match_validation_text");
					if (translationValue) {
						this.errorMsgs.push(translationValue);
					} else {
						this.errorMsgs.push("Your passwords do not match");
					}
				} else {
					this.errorMsgs.push("Your passwords do not match");
				}
				// this.errorMsgs.push("Your passwords do not match");
			}
			if (this.strengthValue < 3) {
				// this.errorMsgs.push("Please choose a stronger password");
			}
			return this.errorMsgs.length < 1;
		}

		update(): void {
			if (this.validate()) {
				const payload = {
					email: this.user.identifier,
					password: this.password,
				};
				resetPass(this.hash, payload).then((result) => {
					if (result.valid) {
						this.hasUpdated = true;
						toLocal("updated", {
							hash: this.hash,
							user: this.user,
						});
						setTimeout(() => {
							this.$router.push("/reset/updated");
						}, 250);
					}
				});
			}
		}

		@Watch("password")
		changePassword(newVal: string) {
			this.password = newVal.trim().replace(/\s+/, "");
			this.validate(false);
			if (this.password.length < 5) {
				this.strengthValue = 0;
			}
		}

		@Watch("cpassword")
		changeCpassword() {
			this.validate(false);
		}

		getTranslation(languageId: string, key: string): string | null {
			const translationItem = this.user.translate.find((item) => item.key === key);
			if (translationItem) {
				const langTranslation = translationItem.translate.find((lang) => lang.languageId === languageId);
				if (langTranslation && langTranslation.isPublished) {
					return langTranslation.value;
				}
			}
			return null; // Return null if not found
		}

		getLanguageList() {
			this.languageList = this.user.languageList.map((item) => {
				return {
					language: item.language,
					key: item.key,
				};
			});
		}
	}
